import axios from '@/common/axios'
import qs from 'qs'

export function addOrderReview (data) {
  return axios({
    method: 'post',
    url: '/sale/orderReview/add',
    data: qs.stringify(data)
  })
}

export function deleteOrderReview (id) {
  return axios({
    method: 'delete',
    url: '/sale/orderReview/delete/' + id
  })
}

export function updateOrderReview (data) {
  return axios({
    method: 'put',
    url: '/sale/orderReview/update',
    data: qs.stringify(data)
  })
}

export function selectOrderReviewInfo (id) {
  return axios({
    method: 'get',
    url: '/sale/orderReview/info/' + id
  })
}

export function selectOrderReviewList (query) {
  return axios({
    method: 'get',
    url: '/sale/orderReview/list',
    params: query
  })
}

export function storageOrderReview (data) {
  return axios({
    method: 'put',
    url: '/sale/orderReview/storageReview',
    data: qs.stringify(data)
  })
}

export function purchaseOrderReview (data) {
  return axios({
    method: 'put',
    url: '/sale/orderReview/purchaseReview',
    data: qs.stringify(data)
  })
}

export function produceOrderReview (data) {
  return axios({
    method: 'put',
    url: '/sale/orderReview/productionDeviceReview',
    data: qs.stringify(data)
  })
}

export function qualityOrderReview (data) {
  return axios({
    method: 'put',
    url: '/sale/orderReview/qualityReview',
    data: qs.stringify(data)
  })
}

export function saleOrderReview (data) {
  return axios({
    method: 'put',
    url: '/sale/orderReview/saleReview',
    data: qs.stringify(data)
  })
}
