import axios from '@/common/axios'
import qs from 'qs'

export function addNotice (data) {
  return axios({
    method: 'post',
    url: '/sale/notice/add',
    data: qs.stringify(data)
  })
}

export function deleteNotice (id) {
  return axios({
    method: 'delete',
    url: '/sale/notice/delete/' + id
  })
}

export function updateNotice (data) {
  return axios({
    method: 'put',
    url: '/sale/notice/update',
    data: qs.stringify(data)
  })
}

export function selectNoticeInfo (id) {
  return axios({
    method: 'get',
    url: '/sale/notice/info/' + id
  })
}

export function selectNoticeList (query) {
  return axios({
    method: 'get',
    url: '/sale/notice/list',
    params: query
  })
}

export function reviewNotice (data) {
  return axios({
    method: 'put',
    url: '/sale/notice/review',
    data: qs.stringify(data)
  })
}

export function approvalNotice (data) {
  return axios({
    method: 'put',
    url: '/sale/notice/approval',
    data: qs.stringify(data)
  })
}

export function selectNoticeByNo (noticeNo) {
  return axios({
    method: 'get',
    url: '/sale/notice/selectByNo/' + noticeNo
  })
}

export function selectCustomerList () {
  return axios({
    method: 'get',
    url: '/sale/notice/customerList'
  })
}

export function noticePrint (id) {
  return axios({
    method: 'get',
    url: '/sale/notice/print/'+id,
  })
}

export function excelExport (ids) {
  return axios({
    method: 'get',
    url: '/sale/notice/export/' + ids
  })
}
