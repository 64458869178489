<template>
  <div id="orderReview">
    <el-dialog
      :title="orderReviewFormTitle"
      width="1500px"
      :visible.sync="orderReviewDialogVisible"
      :close-on-click-modal="false"
      @close="orderReviewDialogClose"
    >
      <el-form
        ref="orderReviewFormRef"
        :model="orderReviewForm"
        :rules="orderReviewFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <!-- <el-col :span="8">
            <el-form-item label="订单号" prop="orderNo">
              <el-input
                v-model="orderReviewForm.orderNo"
                placeholder="请输入订单号"
                :disabled="orderReviewFormTitle !== '新增订单评审表'
                  && orderReviewFormTitle !== '修改订单评审表'
                  && orderReviewFormTitle !== '订单评审表详情'"
              >
                <i slot="suffix" class="el-input__icon el-icon-success" @click="selectNotice" />
              </el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item label="订单号" prop="orderNo">
              <el-select
                v-model="orderReviewForm.orderNo"
                placeholder="请选择订单号"
                clearable
                filterable
                :disabled="orderReviewFormTitle !== '新增订单评审表'
                  && orderReviewFormTitle !== '修改订单评审表'
                  && orderReviewFormTitle !== '订单评审表详情'"
                @change="selectNotice"
              >
                <el-option
                  v-for="item in noticeList"
                  :key="item.id"
                  :label="item.noticeNo"
                  :value="item.noticeNo"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="评审日期" prop="reviewDate">
              <el-date-picker
                v-model="orderReviewForm.reviewDate"
                placeholder="请选择评审日期"
                value-format="yyyy-MM-dd"
                :disabled="orderReviewFormTitle !== '新增订单评审表'
                  && orderReviewFormTitle !== '修改订单评审表'
                  && orderReviewFormTitle !== '订单评审表详情'"
              />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-select
                v-model="orderReviewForm.productName"
                placeholder="请选择产品"
                clearable
                :disabled="orderReviewFormTitle !== '新增订单评审表'
                  && orderReviewFormTitle !== '修改订单评审表'
                  && orderReviewFormTitle !== '订单评审表详情'"
                @change="productChange"
              >
                <el-option
                  v-for="item in productList"
                  :key="item.id"
                  :label="item.productName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="型号规格" prop="spec">
              <el-input
                v-model="orderReviewForm.spec"
                placeholder="请输入型号规格"
                clearable
                :disabled="orderReviewFormTitle !== '新增订单评审表'
                  && orderReviewFormTitle !== '修改订单评审表'
                  && orderReviewFormTitle !== '订单评审表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input
                v-model="orderReviewForm.quantity"
                placeholder="请输入数量"
                clearable
                :disabled="orderReviewFormTitle !== '新增订单评审表'
                  && orderReviewFormTitle !== '修改订单评审表'
                  && orderReviewFormTitle !== '订单评审表详情'"
              />
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item label="发货期限" prop="deliveryPeriod">
              <el-input
                v-model="orderReviewForm.deliveryPeriod"
                placeholder="请输入发货期限"
                clearable
                :disabled="orderReviewFormTitle !== '新增订单评审表'
                  && orderReviewFormTitle !== '修改订单评审表'
                  && orderReviewFormTitle !== '订单评审表详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item>
              <vxe-table
                ref="xTable"
                border
                resizable
                show-overflow
                keep-source
                :data="orderReviewDetailList"
                :edit-config="{ trigger: 'click', mode: 'cell' }"
              >
              <vxe-table-column
                field="productNo"
                title="产品编号"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="productName"
                title="产品名称"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="spec"
                title="规格型号"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="quantity"
                title="数量"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <!-- <vxe-table-column
                v-if="
                  orderReviewFormTitle === '新增订单评审表' ||
                    orderReviewFormTitle === '修改订单评审表'
                "
                title="操作"
                width="100"
              >
                <template #default="{ row }">
                  <template>
                    <vxe-button @click="removeRowEvent(row)">
                      删除
                    </vxe-button>
                  </template>
                </template>
              </vxe-table-column> -->
            </vxe-table>
          </el-form-item>
          <el-col :span="8">
            <el-form-item label="客户（特殊）要求" prop="specialRequirement">
              <el-input
                v-model="orderReviewForm.specialRequirement"
                placeholder="请输入客户（特殊）要求"
                clearable
                :disabled="orderReviewFormTitle !== '新增订单评审表'
                  && orderReviewFormTitle !== '修改订单评审表'
                  && orderReviewFormTitle !== '订单评审表详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="仓储部" prop="storageReview">
              <el-select
                v-model="orderReviewForm.storageReview"
                placeholder="请选择"
                clearable
                :disabled="orderReviewFormTitle !== '仓储部订单评审'
                  && orderReviewFormTitle !== '订单评审表详情'"
              >
                <el-option
                  v-for="item in ['有库存，可直接发货', '无库存，需生产，仓库原材料满足', '无库存，需生产，仓库原材料不足，需采购', '其他']"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预计发货日期" prop="storageReview1Content">
              <el-date-picker
                v-model="orderReviewForm.storageReview1Content"
                placeholder="请选择预计发货日期"
                value-format="yyyy-MM-dd"
                :disabled="orderReviewFormTitle !== '仓储部订单评审'
                  && orderReviewFormTitle !== '订单评审表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="其他" prop="storageReview4Content">
              <el-input
                v-model="orderReviewForm.storageReview4Content"
                placeholder="请输入其他"
                clearable
                :disabled="orderReviewFormTitle !== '仓储部订单评审'
                  && orderReviewFormTitle !== '订单评审表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注">
              若有库存，则无需进行以下评审，销售部确认即可。
              若无库存，需生产，仓库原材料满足，则生产设备部、质量部需进行评审，销售部确认。
              若无库存，需生产，仓库原材料不足，则采购部、生产设备部、质量部需进行评审，销售部确认。
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="采购部" prop="purchaseReview">
              <el-select
                v-model="orderReviewForm.purchaseReview"
                placeholder="请选择"
                clearable
                :disabled="orderReviewFormTitle !== '采购部订单评审'
                  && orderReviewFormTitle !== '订单评审表详情'"
              >
                <el-option
                  v-for="item in ['可采购', '不能采购']"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预计到货日期" prop="purchaseReview1Content">
              <el-date-picker
                v-model="orderReviewForm.purchaseReview1Content"
                placeholder="请选择预计到货日期"
                value-format="yyyy-MM-dd"
                :disabled="orderReviewFormTitle !== '采购部订单评审'
                  && orderReviewFormTitle !== '订单评审表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="原因" prop="purchaseReview2Content">
              <el-input
                v-model="orderReviewForm.purchaseReview2Content"
                placeholder="请输入原因"
                clearable
                :disabled="orderReviewFormTitle !== '采购部订单评审'
                  && orderReviewFormTitle !== '订单评审表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="生产设备部" prop="productionDeviceReview">
              <el-select
                v-model="orderReviewForm.productionDeviceReview"
                placeholder="请选择"
                clearable
                :disabled="orderReviewFormTitle !== '生产设备部订单评审'
                  && orderReviewFormTitle !== '订单评审表详情'"
              >
                <el-option
                  v-for="item in ['生产能力能满足', '生产能力不能满足', '其他']"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="计划生产日期" prop="productionDeviceReview1Content">
              <el-date-picker
                v-model="orderReviewForm.productionDeviceReview1Content"
                placeholder="请选择计划生产日期"
                value-format="yyyy-MM-dd"
                :disabled="orderReviewFormTitle !== '生产设备部订单评审'
                  && orderReviewFormTitle !== '订单评审表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="原因" prop="productionDeviceReview2Content">
              <el-input
                v-model="orderReviewForm.productionDeviceReview2Content"
                placeholder="请输入原因"
                clearable
                :disabled="orderReviewFormTitle !== '生产设备部订单评审'
                  && orderReviewFormTitle !== '订单评审表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="其他" prop="productionDeviceReview3Content">
              <el-input
                v-model="orderReviewForm.productionDeviceReview3Content"
                placeholder="请输入其他"
                clearable
                :disabled="orderReviewFormTitle !== '生产设备部订单评审'
                  && orderReviewFormTitle !== '订单评审表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="质量部" prop="qualityReview">
              <el-select
                v-model="orderReviewForm.qualityReview"
                placeholder="请选择"
                clearable
                :disabled="orderReviewFormTitle !== '质量部订单评审'
                  && orderReviewFormTitle !== '订单评审表详情'"
              >
                <el-option
                  v-for="item in ['质量保证能力能满足', '质量保证能力不能满足', '其他']"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="原因" prop="qualityReview2Content">
              <el-input
                v-model="orderReviewForm.qualityReview2Content"
                placeholder="请输入原因"
                clearable
                :disabled="orderReviewFormTitle !== '质量部订单评审'
                  && orderReviewFormTitle !== '订单评审表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="其他" prop="qualityReview3Content">
              <el-input
                v-model="orderReviewForm.qualityReview3Content"
                placeholder="请输入其他"
                clearable
                :disabled="orderReviewFormTitle !== '质量部订单评审'
                  && orderReviewFormTitle !== '订单评审表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" style="height: 62.8px;">
            <el-form-item label="销售部" prop="saleReview">
              <el-radio-group
                v-model="orderReviewForm.saleReview"
                :disabled="orderReviewFormTitle !== '销售部订单评审'
                  && orderReviewFormTitle !== '订单评审表详情'"
              >
                <el-radio label="通过">
                  通过
                </el-radio>
                <el-radio label="不通过">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="原因" prop="saleReview2Content">
              <el-input
                v-model="orderReviewForm.saleReview2Content"
                placeholder="请输入原因"
                clearable
                :disabled="orderReviewFormTitle !== '销售部订单评审'
                  && orderReviewFormTitle !== '订单评审表详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="orderReviewDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="orderReviewFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="订单号">
        <el-input v-model="searchForm.orderNo" placeholder="请输入订单号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['ORDER_REVIEW_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="orderReviewPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="orderNo" label="订单号" />
      <el-table-column label="评审日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="型号规格" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="deliveryPeriod" label="发货期限" />
      <el-table-column prop="specialRequirement" label="客户（特殊）要求" />
      <el-table-column prop="storageReview" label="仓储部" />
      <el-table-column prop="storageReview1Content" label="预计发货日期" />
      <el-table-column prop="storageReview4Content" label="其他" />
      <el-table-column prop="storageSignature" label="仓储部" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.storageSignatureDate">{{ scope.row.storageSignatureDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="purchaseReview" label="采购部" />
      <el-table-column prop="purchaseReview1Content" label="预计到货日期" />
      <el-table-column prop="purchaseReview2Content" label="原因" />
      <el-table-column prop="purchaseSignature" label="采购部" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.purchaseSignatureDate">{{ scope.row.purchaseSignatureDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="productionDeviceReview" label="生产设备部" />
      <el-table-column prop="productionDeviceReview1Content" label="计划生产日期" />
      <el-table-column prop="productionDeviceReview2Content" label="原因" />
      <el-table-column prop="productionDeviceReview3Content" label="其他" />
      <el-table-column prop="productionSignature" label="生产设备部" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.productionSignatureDate">{{ scope.row.productionSignatureDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="qualityReview" label="质量部" />
      <el-table-column prop="qualityReview2Content" label="原因" />
      <el-table-column prop="qualityReview3Content" label="其他" />
      <el-table-column prop="qualitySignature" label="质量部" />
      <el-table-column label="日期 ">
        <template slot-scope="scope">
          <span v-if="scope.row.qualitySignatureDate">{{ scope.row.qualitySignatureDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saleReview" label="销售部" />
      <el-table-column prop="saleReview2Content" label="原因" />
      <el-table-column prop="saleSignature" label="评审确认" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.saleSignatureDate">{{ scope.row.saleSignatureDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['ORDER_REVIEW_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['ORDER_REVIEW_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['ORDER_REVIEW_STORAGE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleStorage(scope.$index, scope.row)"
          >
            仓储部
          </el-button>
          <el-button
            v-if="checkPermission(['ORDER_REVIEW_PURCHASE']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handlePurchase(scope.$index, scope.row)"
          >
            采购部
          </el-button>
          <el-button
            v-if="checkPermission(['ORDER_REVIEW_PRODUCE']) && scope.row.status === 3"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleProduce(scope.$index, scope.row)"
          >
            生产设备部
          </el-button>
          <el-button
            v-if="checkPermission(['ORDER_REVIEW_QUALITY']) && scope.row.status === 4"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleQuality(scope.$index, scope.row)"
          >
            质量部
          </el-button>
          <el-button
            v-if="checkPermission(['ORDER_REVIEW_SALE']) && scope.row.status === 5"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleSale(scope.$index, scope.row)"
          >
            销售部
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="orderReviewPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addOrderReview,
  deleteOrderReview,
  updateOrderReview,
  selectOrderReviewInfo,
  selectOrderReviewList,
  storageOrderReview,
  purchaseOrderReview,
  produceOrderReview,
  qualityOrderReview,
  saleOrderReview
} from '@/api/sale/orderReview'
import { selectNoticeList, selectNoticeByNo } from '@/api/sale/notice'

export default {
  data () {
    return {
      orderReviewDialogVisible: false,
      orderReviewFormTitle: '',
      orderReviewForm: {
        id: '',
        orderNo: '',
        reviewDate: new Date().toLocaleDateString(),
        productName: '',
        spec: '',
        quantity: '',
        deliveryPeriod: '',
        specialRequirement: '',
        storageReview: '',
        storageReview1Content: '',
        storageReview4Content: '',
        purchaseReview: '',
        purchaseReview1Content: '',
        purchaseReview2Content: '',
        productionDeviceReview: '',
        productionDeviceReview1Content: '',
        productionDeviceReview2Content: '',
        productionDeviceReview3Content: '',
        qualityReview: '',
        qualityReview2Content: '',
        qualityReview3Content: '',
        saleReview: '',
        saleReview2Content: '',
        status: '',
        taskId: '',
        orderReviewDetailJson: ''
      },
      orderReviewFormRules: {
        orderNo: [{ required: true, message: '订单号不能为空', trigger: ['blur', 'change']}]
      },
      orderReviewPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        orderNo: ''
      },
      productList: [],
      noticeList: [],
      orderReviewDetailList: []
    }
  },
  created () {
    selectOrderReviewList(this.searchForm).then(res => {
      this.orderReviewPage = res
      this.productList = []
    })
    selectNoticeList().then(res => {
      this.noticeList = res.list
    })
  },
  methods: {
    orderReviewDialogClose () {
      this.$refs.orderReviewFormRef.resetFields()
      this.orderReviewDetailList = []
    },
    orderReviewFormSubmit () {
      if (this.orderReviewFormTitle === '订单评审表详情') {
        this.orderReviewDialogVisible = false
        return
      }
      this.$refs.orderReviewFormRef.validate(async valid => {
        if (valid) {
          if (this.orderReviewFormTitle === '新增订单评审表') {
            this.orderReviewForm.id = ''
            this.orderReviewForm.status = 1
            this.orderReviewForm.orderReviewDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addOrderReview(this.orderReviewForm)
          } else if (this.orderReviewFormTitle === '修改订单评审表') {
            this.orderReviewForm.orderReviewDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateOrderReview(this.orderReviewForm)
          } else if (this.orderReviewFormTitle === '仓储部订单评审') {
            this.orderReviewForm.status = 2
            await storageOrderReview(this.orderReviewForm)
          } else if (this.orderReviewFormTitle === '采购部订单评审') {
            this.orderReviewForm.status = 3
            await purchaseOrderReview(this.orderReviewForm)
          } else if (this.orderReviewFormTitle === '生产设备部订单评审') {
            this.orderReviewForm.status = 4
            await produceOrderReview(this.orderReviewForm)
          } else if (this.orderReviewFormTitle === '质量部订单评审') {
            this.orderReviewForm.status = 5
            await qualityOrderReview(this.orderReviewForm)
          } else if (this.orderReviewFormTitle === '销售部订单评审') {
            this.orderReviewForm.status = 6
            await saleOrderReview(this.orderReviewForm)
          }
          this.orderReviewPage = await selectOrderReviewList(this.searchForm)
          this.orderReviewDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.orderReviewFormTitle = '新增订单评审表'
      this.orderReviewDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteOrderReview(row.id)
        if (this.orderReviewPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.orderReviewPage = await selectOrderReviewList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.orderReviewFormTitle = '修改订单评审表'
      this.orderReviewDialogVisible = true
      this.selectOrderReviewInfoById(row.id)
    },
    handleStorage (index, row) {
      this.orderReviewFormTitle = '仓储部订单评审'
      this.orderReviewDialogVisible = true
      this.selectOrderReviewInfoById(row.id)
    },
    handlePurchase (index, row) {
      this.orderReviewFormTitle = '采购部订单评审'
      this.orderReviewDialogVisible = true
      this.selectOrderReviewInfoById(row.id)
    },
    handleProduce (index, row) {
      this.orderReviewFormTitle = '生产设备部订单评审'
      this.orderReviewDialogVisible = true
      this.selectOrderReviewInfoById(row.id)
    },
    handleQuality (index, row) {
      this.orderReviewFormTitle = '质量部订单评审'
      this.orderReviewDialogVisible = true
      this.selectOrderReviewInfoById(row.id)
    },
    handleSale (index, row) {
      this.orderReviewFormTitle = '销售部订单评审'
      this.orderReviewDialogVisible = true
      this.selectOrderReviewInfoById(row.id)
    },
    handleInfo (index, row) {
      this.orderReviewFormTitle = '订单评审表详情'
      this.orderReviewDialogVisible = true
      this.selectOrderReviewInfoById(row.id)
    },
    selectOrderReviewInfoById (id) {
      selectOrderReviewInfo(id).then(res => {
        this.orderReviewForm.id = res.id
        this.orderReviewForm.orderNo = res.orderNo
        this.orderReviewForm.reviewDate = res.reviewDate
        this.orderReviewForm.productName = res.productName
        this.orderReviewForm.spec = res.spec
        this.orderReviewForm.quantity = res.quantity
        this.orderReviewForm.deliveryPeriod = res.deliveryPeriod
        this.orderReviewForm.specialRequirement = res.specialRequirement
        this.orderReviewForm.storageReview = res.storageReview
        this.orderReviewForm.storageReview1Content = res.storageReview1Content
        this.orderReviewForm.storageReview4Content = res.storageReview4Content
        this.orderReviewForm.purchaseReview = res.purchaseReview
        this.orderReviewForm.purchaseReview1Content = res.purchaseReview1Content
        this.orderReviewForm.purchaseReview2Content = res.purchaseReview2Content
        this.orderReviewForm.productionDeviceReview = res.productionDeviceReview
        this.orderReviewForm.productionDeviceReview1Content = res.productionDeviceReview1Content
        this.orderReviewForm.productionDeviceReview2Content = res.productionDeviceReview2Content
        this.orderReviewForm.productionDeviceReview3Content = res.productionDeviceReview3Content
        this.orderReviewForm.qualityReview = res.qualityReview
        this.orderReviewForm.qualityReview2Content = res.qualityReview2Content
        this.orderReviewForm.qualityReview3Content = res.qualityReview3Content
        this.orderReviewForm.saleReview = res.saleReview
        this.orderReviewForm.saleReview2Content = res.saleReview2Content
        this.orderReviewForm.status = res.status
        this.orderReviewForm.taskId = res.taskId
        this.orderReviewDetailList = res.orderReviewDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectOrderReviewList(this.searchForm).then(res => {
        this.orderReviewPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectOrderReviewList(this.searchForm).then(res => {
        this.orderReviewPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectOrderReviewList(this.searchForm).then(res => {
        this.orderReviewPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['ORDER_REVIEW_STORAGE']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['ORDER_REVIEW_PURCHASE']) && row.status === 2) {
        return 'review'
      } else if (this.checkPermission(['ORDER_REVIEW_PRODUCE']) && row.status === 3) {
        return 'review'
      } else if (this.checkPermission(['ORDER_REVIEW_QUALITY']) && row.status === 4) {
        return 'review'
      } else if (this.checkPermission(['ORDER_REVIEW_SALE']) && row.status === 5) {
        return 'review'
      }
      return ''
    },
    selectNotice (value) {
      this.orderReviewForm.deliveryPeriod = ''
      this.productList = []
      this.orderReviewDetailList = []
      this.orderReviewForm.productName = ''
      this.orderReviewForm.spec = ''
      this.orderReviewForm.quantity = ''
      if (this.orderReviewForm.orderNo) {
        selectNoticeByNo(this.orderReviewForm.orderNo).then(res => {
          if (res.deliveryDeadline) {
            this.orderReviewForm.deliveryPeriod = res.deliveryDeadline.substring(0, 10)
          }
          this.productList = res.detailList
          for (let item of res.detailList) {
            this.orderReviewDetailList.push({
              productNo: item.productNo,
              productName: item.productName,
              spec: item.spec,
              quantity: item.quantityBatch
            })
          }
        })
      }
    },
    productChange (value) {
      if (value) {
        let product = this.productList.find(item => item.id === value)
        this.orderReviewForm.productName = product.productName
        this.orderReviewForm.spec = product.spec
        this.orderReviewForm.quantity = product.quantityBatch
      } else {
        this.orderReviewForm.productName = ''
        this.orderReviewForm.spec = ''
        this.orderReviewForm.quantity = ''
      }
    }
  }
}
</script>

<style>
</style>
